import React from 'react'
import Button from '../../Button/Button'
import CheckSvg from '../../../../assets/svg/check.svg'
import * as styles from './pricingcard.module.scss'

export default ({name, price, priceExtra, description, benefits, colored, onClick, disabled, offer}) => {
    const Benefits = () => benefits.map((text, index) => {
        return <li key={index}>
            <div className={styles.check}>
            <CheckSvg />
            </div>
            <span>
                {text}
            </span>
        </li>
    })

    return(
        <div className={`${styles.container} ${colored ? styles.colored : null}`}>
            {offer ? 
            <div className={styles.offer}>{offer}</div> : null}
            <span className={styles.name}>{name}</span>
            <span className={styles.price}>${price}<span className={styles.priceExtra}>{priceExtra}</span></span>
            <span className={styles.description}>{description}</span>
            <ul className={styles.benefits}>
                <Benefits />
            </ul>
            <Button disabled={disabled} className={styles.button} onClick={onClick} loaderColor={"#7540ee"}>
                GET STARTED
            </Button>
        </div>
    )
}