import React from 'react'
import PricingCards from './Cards/Cards'
import FAQ from './FAQ/FAQ'
import Comparison from './Comparison/Comparison'
import * as styles from './pricing.module.scss'

export default ({faq, medium, comparison}) => {

    function scrollToElement(id){
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth'
        });
    }

    return(
        <div className={`${styles.pricingContainer} ${medium ? styles.medium : undefined}`}>
            <h2 id="pricing">Pricing</h2>
            <h3>
                Flexible packages to suit your use-case. < br />
                Choose between Free, Pay-as-you-Download and Subscription plans.
            </h3>
            <PricingCards />
            {
                comparison ?
                <div className={styles.comparison}>
                    {/* <span className={styles.moreFeatures} onClick={() => scrollToElement("comparison")}>See more features</span> */}
                    <Comparison />
                </div> : null
            }
            {faq ?
            <FAQ /> : null}
        </div>
    )
}