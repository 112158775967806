import React from "react";
import * as styles from "./comparison.module.scss";
import CheckSvg from '../../../../assets/svg/check.svg';
import CrossSvg from '../../../../assets/svg/cancel.svg';





const Comparison = () => {

    function scrollToElement(id){
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth'
        });
    }

    let data = [
        [<h4>Design Features</h4>, "", "", ""],
        ["2D Editor", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["3D Editor", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Layout Options", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Styling", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Custom Fonts", <CrossSvg />, <CrossSvg />, <CheckSvg className={styles.green} />],
        ["Transparent Background", <CrossSvg />, <CrossSvg />, <CheckSvg className={styles.green} />],
        [<h4>Design Components</h4>, "", "", ""],
        ["All Devices", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Clay Variations", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Background Collection", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Icon Collection", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Text Animations", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        [<h4>Templates</h4>, "", "", ""],
        ["Pre-made Templates", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Template Storage Slots", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Template Storage Access", <CrossSvg />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Team Sharing", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        [<h4>Exports</h4>, "", "", ""],
        ["PNG, JPG, MP4 Exports", <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Commercial License", <CrossSvg />, <CheckSvg className={styles.green} />, <CheckSvg className={styles.green} />],
        ["Video Export", <CrossSvg />, '30 FPS', '60 FPS'],
        ["Exports", "Unlimited", "Credits", "Unlimited"],
        ["Export Quality", '720p', '1080p+', '1080p+'],
    ]
    return (
        <div className={styles.container}>
            <h2 id="comparison">Compare Features</h2>
            <table>
                <thead>
                    <tr>
                        <th/>
                        <th>
                            Lite
                        </th>
                        <th>
                            Plus
                        </th>
                        <th>
                            Pro
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((feature, index) => (
                        <tr key={index}>
                        {
                        feature.map((item, i) => (
                            <td key={i}>{item}</td>
                        ))
                        }
                        </tr>
                    ))}
                </tbody>
            </table>
            <span className={styles.toTop} onClick={() => scrollToElement("pricing")}>Back to Pricing</span>
        </div>
    )
}

export default Comparison;

