// extracted by mini-css-extract-plugin
export const container = "pricingcard-module--container--2I7S4";
export const name = "pricingcard-module--name--sHLPF";
export const price = "pricingcard-module--price--3ZT61";
export const priceExtra = "pricingcard-module--price-extra--1UiGh";
export const description = "pricingcard-module--description--3OjPY";
export const offer = "pricingcard-module--offer--2ESuI";
export const benefits = "pricingcard-module--benefits--2RnVL";
export const check = "pricingcard-module--check--1N-Y0";
export const button = "pricingcard-module--button--2m99g";
export const colored = "pricingcard-module--colored--3Aztr";