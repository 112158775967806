import React from "react";
import Collapsible from "react-collapsible";
import * as styles from "./faq.module.scss";

export default () => {
  function Tab({ children, title }) {
    return (
      <Collapsible
        trigger={title}
        contentOuterClassName={styles.outer}
        contentInnerClassName={styles.inner}
        className={styles.closed}
        openedClassName={styles.opened}
        triggerClassName={styles.closedTrigger}
        triggerOpenedClassName={styles.openedTrigger}
      >
        {children}
      </Collapsible>
    );
  }
  return (
    <div className={styles.container}>
      <h2>FAQs</h2>
      <Tab title={`What is an export?`}>
        <p>
          A single "export" allows you to download a mockup that you created
          using Previewed. All exports such as 2D, 3D, videos and animations
          count for 1 export, each time you export them.
        </p>
        <p>
          There may be some confusion with regard to mockups that consist of
          several images/parts, for example the panorama mockups. We want to
          stress that a single download constitutes a download of a single
          mockup, irrespective of how many 'images' are in it. Thus, a panorama
          mockup would only cost 1 download.
        </p>
      </Tab>
      <Tab title={`Are exports recurring?`}>
        <p>
          By default, exports are non-recurring. If you need exports on continuos basis, you should buy the subscription plan. If you buy our one-time payment packages, unless explicitly stated otherwise, the "exports" are not recurring. This means that we substract 1 credit from your balance after each download. 
        </p>
      </Tab>
      <Tab title={`Can you send an invoice?`}>
        <p>
          Invoicing is only available for custom plans. Please get in touch to build your custom package. We can only provide invoices for custom packages. We provide receipts as proof of payment with every charge. 
        </p>
      </Tab>
      <Tab title={`Is there an expiry date on exports?`}>
        <p>
          No. The exports that you purchase will be in your account forever.
          The count will only decrease as you download mockups.
        </p>
      </Tab>
      <Tab title={`What is the commercial license?`}>
        <p>
            Commercial license gives you the right to use generated mockups for commercial purposes, without any attribution required. You can distribute generated mockups to any medium (Web, Mobile, TV etc.). You can use it to resell similar services to your clients. By default, all generated assets and mockups in the FREE plan are for personal use only. If on the FREE plan, you will need to attribute and credit Previewed wherever the mockups are used.
        </p>
      </Tab>
      <Tab title={`What is template storage?`}>
        <p>
            Previewed allows you to create mockups. Once the mockup is created, you might want to save it as a template, so that you can come back to it later. This is possible by saving the template. You can organise your templates into groups and share access to these groups with other team members.
        </p>
      </Tab>
      <Tab title={`What is team sharing?`}>
        <p>
            Saved templates are organized into groups. These groups can be shared with your team, giving your team access to all templates in that group.
        </p>
      </Tab>
      <Tab title={`Subscription Cancellation`}>
        <p>
          You can cancel your subsciption at any time. To cancel the subsciption,
          go to the account page and click 'Cancel Subscription'. Once cancelled, you will be able to continue to use the subcription benefits up until your paid period ends. 
        </p>
        <p>
          Upon cancelling the subscription you will continue to have access to benefits of the PRO plan up until the payment period end. At the same time, you will be downgraded to the FREE plan.
        </p>
      </Tab>
    </div>
  );
};
