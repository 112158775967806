import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../firebase/api";
import { setLoginModal } from "../../../../slices/user-slice";
import * as styles from "./cards.module.scss";
import PricingCardNew from "../PricingCard/PricingCard";
import { push_event_to_datalayer } from '../../../../tools/analytics';
import Toggle from 'react-toggle';
import "react-toggle/style.css"


export default () => {
  let dispatch = useDispatch();
  let userSignedIn = useSelector((state) => state.UserSlice.user);

  const [unlimited, setUnlimited] = React.useState(null);
  const [annual, setAnnual] = React.useState(true);

  function handleToggle(){
    if (annual==true) {
      setAnnual(false)
    }
    else {
      setAnnual(true)
    }
  }


  async function selectCredits(priceId, mode) {
    if (!userSignedIn) {
      dispatch(setLoginModal(true));
      return;
    }
    push_event_to_datalayer('stripe-checkout-started');
    let stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
    let sessionId = await API.startStripeCheckout(priceId, mode);
    if (sessionId && stripe) {
      stripe.redirectToCheckout({
        sessionId,
      });
    }
    return;
  }

  React.useEffect(() => {
    async function getUserCredits() {
      let response = await API.canUserDownload();
      if (response) {
        setUnlimited(response.unlimited || response.subscription ? true : false);
      }
    }
    if (userSignedIn) {
      getUserCredits();
    } else {
      setUnlimited(null);
    }
  }, [userSignedIn]);
  return (
    <div className = {styles.pricingTable}>
      <div className={styles.toggle}>
      <span>Monthly Billing</span>
      <Toggle
        defaultChecked={annual}
        aria-label='No label tag'
        onChange={handleToggle} />
      <span>Annual Billing <span className={styles.save}>Save 35%</span></span>
      </div>
      <div className={styles.pricingCards}>
      <PricingCardNew
        disabled={userSignedIn}
        name={"Lite"}
        price={0}
        priceExtra={"free-forever"}
        description={
          "To showcase a single app or project."
        }
        benefits={["Unlimited 2D Exports (720p)", "CC Attribution License"]}
        onClick={() => dispatch(setLoginModal(true))}
      />
      <PricingCardNew
        disabled={unlimited}
        name={"Plus"}
        price={9.99}
        priceExtra={"one time fee"}
        description={
          "To showcase apps commercially."
        }
        benefits={[
          "All features in Lite",
          "+10 Exports (1080p+)",
          "3D Exports",
          "Video Exports (30fps)",
          "Commercial License",
        ]}
        onClick={() =>
          selectCredits(process.env.GATSBY_PRICE_PLUS, "payment")
        }
      />
      <PricingCardNew
        disabled={unlimited}
        colored
        name={"Pro"}
        price={annual ? 19 : 29}
        priceExtra={annual ? "/month. Billed $228 per year." : "/month"}
        offer={annual ? "Annual Plan" : null}
        description={
          "To create mockups on continuous basis."
        }
        benefits={[
          "Unlimited Exports",
          "Pro Features",
          "3D Exports",
          "Video Exports (60fps)",
          "Commercial License",
          "Priority Support"
        ]}
        onClick={() => selectCredits(annual ? process.env.GATSBY_PRICE_PRO : process.env.GATSBY_PRICE_PRO_MONTHLY, "subscription")}
      />
      </div>
    </div>
  );
};
